import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import RadioGroup from '@shared/ui/inputs/RadioGroup';
import { ButtonContainer as ButtonContainerComponent, SubmitButton as SubmitButtonComponent, } from '../../../../../components/shared';
import { maskEmail } from '../../../../../../lib/utils';
import { ResetPasswordVariants } from '../../../../../../lib/constants';
const ResetFormContainer = styled.div();
const TextContainer = styled.div(props => ({
    userSelect: 'none',
    marginBottom: props.theme.spacing_sizes.xs * 5,
}));
const RadioGroupContainer = styled.div(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 4,
}));
const ButtonContainer = styled(ButtonContainerComponent)(props => ({
    marginBottom: props.theme.spacing_sizes.l,
}));
const SubmitButton = styled(SubmitButtonComponent)(() => ({
    width: '100%',
}));
const ResetPasswordForm = ({ onSubmit, emailForReset = '', isResetting, }) => {
    const { t } = useTranslation();
    const [resetVariant, setResetVariant] = useState(ResetPasswordVariants.EMAIL);
    const handleRadioChange = (_, value) => {
        setResetVariant(value);
    };
    const handleSubmit = () => {
        onSubmit(resetVariant);
    };
    return (_jsxs(ResetFormContainer, { children: [_jsx(TextContainer, { children: t('auth.reset_password.steps.reset_password_step.text', { ns: 'common' }) }), _jsx(RadioGroupContainer, { children: _jsx(RadioGroup, { name: 'choose-reset-variant', options: [{
                            value: ResetPasswordVariants.EMAIL,
                            label: t('auth.reset_password.steps.reset_password_step.radio_options.email', {
                                email: maskEmail(emailForReset || ''),
                                ns: 'common',
                            }),
                        }], value: resetVariant, onChange: handleRadioChange, disabled: isResetting }) }), _jsx(ButtonContainer, { children: _jsx(SubmitButton, { onClick: handleSubmit, isLoading: isResetting, disabled: isResetting, children: t('auth.reset_password.steps.reset_password_step.button_text', { ns: 'common' }) }) })] }));
};
export default ResetPasswordForm;
