var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useWizard } from '@shared/ui/misc/Wizard';
import useAuth from '@pages/auth/lib/useAuth';
import FormWrapper from '../../../../components/FormWrapper';
import ResetPasswordForm from './ResetPasswordForm';
const ResetPasswordStep = () => {
    const { t } = useTranslation();
    const { goNext, goBack } = useWizard();
    const { closeResetForm, obtainResetLink, emailForReset, isResetting, } = useAuth();
    const handleSubmit = (variant) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield obtainResetLink(variant);
        if (res) {
            goNext();
        }
        return res;
    });
    return (_jsx(FormWrapper, { renderInnerForm: (_jsx(ResetPasswordForm, { isResetting: isResetting, onSubmit: handleSubmit, emailForReset: emailForReset })), titleText: t('auth.reset_password.steps.reset_password_step.title', { ns: 'common' }), backLinkProps: {
            text: t('auth.reset_password.steps.backlink_text', { ns: 'common' }),
            onClick: goBack,
        }, handleReset: closeResetForm, showNavigation: true }));
};
export default ResetPasswordStep;
